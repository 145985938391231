<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/tabs/profile/offline"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.profileOfflineSavedItems.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button>
            <ion-icon
              slot="icon-only"
              :icon="icons.wifiOutline"
              :color="
                networkStatus && networkStatus.connected ? 'success' : 'danger'
              "
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <div class="ion-padding ion-text-center">
        <div style="font-size: 48px">
          <ion-icon :icon="icons.hourglassOutline" slot="start"></ion-icon>
        </div>
        {{ $t('views.profileOfflineSavedItems.description') }}
      </div>
      <div class="ion-padding ion-text-center">
        <ion-button
          expand="block"
          :disabled="
            processing ||
              savedItems.length === 0 ||
              (networkStatus && !networkStatus.connected)
          "
          @click="onProcessAllSavedItems"
        >
          <ODNSpinner v-show="processing" slot="end" />
          <ion-icon
            v-show="!processing"
            :icon="icons.cloudUploadOutline"
            slot="start"
          ></ion-icon>
          <span v-show="!processing">{{ $t('buttons.sendAll') }}</span>
        </ion-button>
      </div>
      <div>
        <ion-card
          v-for="(savedItem, index) in savedItems"
          :key="index"
          button
          @click="onClickSavedItem(savedItem, index)"
        >
          <ion-card-header>
            <ion-card-subtitle>
              {{ formatDate(savedItem.created) }}
            </ion-card-subtitle>
            <ion-card-title>
              {{ savedItem.data.store.retailer }} -
              {{ savedItem.data.store.store }}
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            {{ $t(`labels.${savedItem.type}`) }}
          </ion-card-content>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-card>

        <ODNNoContent v-if="savedItems.length === 0" />
      </div>
      <div v-if="savedItems.length" class="ion-padding">
        <ion-button
          expand="block"
          color="danger"
          fill="outline"
          @click="onDeleteAllSavedItems"
        >
          <ion-icon :icon="icons.trashOutline" slot="start"></ion-icon>
          {{ $t('buttons.clearData') }}
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  toastController,
  alertController,
  loadingController,
} from '@ionic/vue';

import {
  wifiOutline,
  cloudUploadOutline,
  documentOutline,
  trashOutline,
  hourglassOutline,
} from 'ionicons/icons';

import ODNSpinner from '@c/odn-spinner.vue';
import ODNNoContent from '@c/odn-no-content.vue';

import { dataUrlToFile } from '@s/helper';
import APIService from '@s/api.service';

export default {
  name: 'ProfileOfflineSavedItems',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    ODNSpinner,
    ODNNoContent,
  },
  data() {
    return {
      processing: false,
      icons: {
        wifiOutline,
        cloudUploadOutline,
        documentOutline,
        trashOutline,
        hourglassOutline,
      },
    };
  },
  computed: {
    ...mapState('offline', ['savedItems', 'networkStatus']),
  },
  methods: {
    ...mapActions('offline', [
      'deleteOfflineSavedItem',
      'deleteAllOfflineSavedItems',
    ]),
    formatDate(dt) {
      if (!dt) return null;
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async processNewResponse(data) {
      // 1. Send response
      const response = (await APIService.post(`/responses`, data.response))
        .data;

      // 2. Send values
      await APIService.post(
        `/responses/${response.id}/values/bulk`,
        data.values
      );

      // 3. Send signature
      if (data.signature) {
        const randomNumber = new Date().getTime();
        const file = await dataUrlToFile(
          data.signature.picture,
          `signature-${response.id}-${randomNumber}.png`,
          {
            type: `image/png`,
          }
        );

        const formData = new FormData();
        formData.append('file', file);

        if (data.signature.lastname) {
          formData.append('lastname', data.signature.lastname);
        }

        if (data.signature.firstname) {
          formData.append('firstname', data.signature.firstname);
        }

        await APIService.upload(
          `/responses/${response.id}/signature`,
          formData
        );
      }

      // 4. Upload files
      if (data.uploads) {
        for (const upload of data.uploads) {
          const randomNumber = new Date().getTime();
          const ext = upload.file.split('.').pop();
          const rawFile = await Filesystem.readFile({
            path: upload.file,
            directory: Directory.Data,
          });

          const blob = new Blob(
            [new Uint8Array(Base64.toUint8Array(rawFile.data))],
            {
              type: upload.type,
            }
          );

          const file = new File(
            [blob],
            `response-upload-${response.id}-${randomNumber}.${ext}`,
            {
              type: blob.type,
            }
          );

          const formData = new FormData();
          formData.append('surveyFieldId', upload.surveyFieldId);
          formData.append('file', file);

          await APIService.upload(
            `/responses/${response.id}/values/upload`,
            formData
          );
        }
      }
    },
    async processNewTicket(data) {
      // 1. Send ticket
      const ticket = (await APIService.post(`/tickets`, data.ticket)).data;

      // 2. Upload ticket file
      if (data.upload) {
        const randomNumber = new Date().getTime();
        const ext = data.upload.file.split('.').pop();
        const rawFile = await Filesystem.readFile({
          path: data.upload.file,
          directory: Directory.Data,
        });

        const blob = new Blob(
          [new Uint8Array(Base64.toUint8Array(rawFile.data))],
          {
            type: data.upload.type,
          }
        );

        const file = new File(
          [blob],
          `ticket-upload-${ticket.id}-${randomNumber}.${ext}`,
          {
            type: blob.type,
          }
        );

        const formData = new FormData();
        formData.append('file', file);

        await APIService.upload(`/tickets/${ticket.id}/files`, formData);
      }
    },
    async processNewTicketAction(data) {
      // 1. Send ticket action
      const action = (
        await APIService.post(`/tickets/${data.ticketId}/actions`, data.action)
      ).data;

      // 2. Send signature
      if (data.signature) {
        const randomNumber = new Date().getTime();
        const file = await dataUrlToFile(
          data.signature.picture,
          `signature-${action.id}-${randomNumber}.png`,
          {
            type: `image/png`,
          }
        );

        const formData = new FormData();
        formData.append('file', file);

        if (data.signature.lastname) {
          formData.append('lastname', data.signature.lastname);
        }

        if (data.signature.firstname) {
          formData.append('firstname', data.signature.firstname);
        }

        await APIService.upload(
          `/tickets/${data.ticketId}/actions/${action.id}/signature`,
          formData
        );
      }

      // 3. Upload file
      if (data.upload) {
        const randomNumber = new Date().getTime();
        const ext = data.upload.file.split('.').pop();
        const rawFile = await Filesystem.readFile({
          path: data.upload.file,
          directory: Directory.Data,
        });

        const blob = new Blob(
          [new Uint8Array(Base64.toUint8Array(rawFile.data))],
          {
            type: data.upload.type,
          }
        );

        const file = new File(
          [blob],
          `ticket-upload-${action.id}-${randomNumber}.${ext}`,
          {
            type: blob.type,
          }
        );

        const formData = new FormData();
        formData.append('actionId', action.id);
        formData.append('file', file);
        if (data.action.notes) {
          formData.append('notes', data.action.notes);
        }

        await APIService.upload(`/tickets/${data.ticketId}/files`, formData);
      }
    },
    async processSavedItem(savedItem, index) {
      const loadingHandler = await loadingController.create({
        message: this.$t('messages.processing'),
      });
      loadingHandler.present();
      try {
        if (savedItem.type === 'newResponse') {
          await this.processNewResponse(savedItem.data);
        } else if (savedItem.type === 'newTicket') {
          await this.processNewTicket(savedItem.data);
        } else if (savedItem.type === 'newTicketAction') {
          await this.processNewTicketAction(savedItem.data);
        }
        await this.deleteOfflineSavedItem(index);
        const toast = await toastController.create({
          message: this.$t('messages.surveyResponse.post.success'),
          color: 'success',
          duration: 2000,
        });
        return toast.present();
      } catch (error) {
        const toast = await toastController.create({
          message: this.$t('messages.surveyResponse.post.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        loadingHandler.dismiss();
      }
    },
    async onClickSavedItem(savedItem, index) {
      const alert = await alertController.create({
        header: this.$t('alerts.actions.header'),
        buttons: [
          {
            text: this.$t('buttons.cancel'),
            role: 'cancel',
          },
          {
            text: this.$t('buttons.delete'),
            cssClass: 'odn-red-button',
            handler: () => {
              this.deleteOfflineSavedItem(index);
            },
          },
          {
            text: this.$t('buttons.send'),
            handler: () => {
              this.processSavedItem(savedItem, index);
            },
          },
        ],
      });
      return await alert.present();
    },
    async onProcessAllSavedItems() {
      const loadingHandler = await loadingController.create({
        message: this.$t('messages.processing'),
      });
      loadingHandler.present();
      try {
        for (const savedItem of this.savedItems) {
          if (savedItem.type === 'newResponse') {
            await this.processNewResponse(savedItem.data);
          } else if (savedItem.type === 'newTicket') {
            await this.processNewTicket(savedItem.data);
          } else if (savedItem.type === 'newTicketAction') {
            await this.processNewTicketAction(savedItem.data);
          }
        }
        await this.deleteAllOfflineSavedItems();
        const toast = await toastController.create({
          message: this.$t('messages.savedItems.post.success'),
          color: 'success',
          duration: 2000,
        });
        return toast.present();
      } catch (error) {
        const toast = await toastController.create({
          message: this.$t('messages.savedItems.post.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        loadingHandler.dismiss();
      }
    },
    async onDeleteAllSavedItems() {
      const alert = await alertController.create({
        header: this.$t('alerts.deleteAll.header'),
        buttons: [
          {
            text: this.$t('buttons.no'),
            role: 'cancel',
          },
          {
            text: this.$t('buttons.yes'),
            cssClass: 'odn-red-button',
            handler: () => {
              this.deleteAllOfflineSavedItems();
            },
          },
        ],
      });
      return await alert.present();
    },
  },
};
</script>

<style lang="scss">
.odn-red-button {
  color: var(--ion-color-danger) !important;
}
</style>
